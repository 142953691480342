// @flow
/* eslint-disable no-use-before-define */
import * as React from 'react';
import { Formik, Field, type FormikConfig, FormikActions } from 'formik';
import warning from 'warning';
import noop from 'lodash/noop';
import { GCLID_STORAGE_NAME } from '../../../constants.js';

import fetchLeadCreation from '../helpers/fetchLeadCreation';
import fetchSubscribeToList from '../helpers/fetchSubscribeToList';
import cleanseZohoData from '../../../functions/helpers/cleanseZohoData';

import type { OnSubmitMapValues } from '../types';

export type Props<Values> = {
  children?: React.Node,
  contentSource: void | string,
  listKey?: void | string,
  validateOnChange?: $ElementType<FormikConfig<Values>, 'validateOnChange'>,
  validateOnBlur?: $ElementType<FormikConfig<Values>, 'validateOnBlur'>,
  isInitialValid?: $ElementType<FormikConfig<Values>, 'isInitialValid'>,
  enableReinitialize?: $ElementType<FormikConfig<Values>, 'enableReinitialize'>,
  initialValues?: $ElementType<FormikConfig<Values>, 'initialValues'>,
  validate?: $ElementType<FormikConfig<Values>, 'validate'>,
  validationSchema?: $ElementType<FormikConfig<Values>, 'validationSchema'>,
  onReset?: $ElementType<FormikConfig<Values>, 'onReset'>,
  onSubmit?: (Values, FormikActions<Values>) => mixed,
  onSubmitMapValues: OnSubmitMapValues<Values>,
  onSuccess?: (Values, FormikActions<Values>, ?Array<?Response>) => mixed,
  onPartialSuccess?: (
    Values,
    FormikActions<Values>,
    ?Array<?Response>,
  ) => mixed,
  onError?: (Response, Values, FormikActions<Values>) => mixed,
  onReject?: (Error, Values, FormikActions<Values>) => mixed,
};

const defaultOnReject = (error, values, unusedActions) => {
  const errorValue = error.valueOf();
  warning(
    false,
    typeof errorValue === 'string'
      ? errorValue
      : `could not submit form ${values['form-name']}`,
  );
};

const Form = <Values: {}>({
  onSubmitMapValues,
  onSubmit = noop,
  onReset,
  onSuccess = noop,
  onError = noop,
  onPartialSuccess = onSuccess,
  onReject = defaultOnReject,
  children,
  listKey,
  contentSource,
  validate,
  validationSchema,
  validateOnChange,
  validateOnBlur,
  isInitialValid,
  enableReinitialize,
  initialValues,
  ...props
}: Props<{
  ...$Exact<Values>,
  'form-name': string,
}>) => {
  const [GCLID, setGCLID] = React.useState();

  React.useEffect(() => {
    let storageCheckInterval;
    const checkStorageForGCLID = () => {
      if (
        typeof window !== 'undefined' &&
        typeof window.sessionStorage !== 'undefined'
      ) {
        const sessionGCLID = window.sessionStorage.getItem(GCLID_STORAGE_NAME);
        if (!!sessionGCLID) {
          setGCLID(sessionGCLID);
          clearInterval(storageCheckInterval);
        }
      }
    };
    checkStorageForGCLID();
    // And set an interval to look for it every 2 seconds
    storageCheckInterval = setInterval(checkStorageForGCLID, 2 * 1000);
    // Clear interval if the component is unmounted
    return () => clearInterval(storageCheckInterval);
  }, []);
  return (
    <>
      <Formik
        validateOnChange={validateOnChange}
        validateOnBlur={validateOnBlur}
        isInitialValid={isInitialValid}
        enableReinitialize={enableReinitialize}
        initialValues={React.useMemo(() => ({ ...initialValues }), [
          initialValues,
        ])}
        onReset={onReset}
        onSubmit={React.useCallback(
          (originalValues, actions) => {
            const values = onSubmitMapValues({
              ...originalValues,
              gclid: GCLID,
            });
            Promise.resolve(onSubmit(originalValues, actions))
              .then(() =>
                Promise.all([
                  listKey && values.subscribe
                    ? fetchSubscribeToList({
                        ...values.subscribe,
                        listKey,
                      })
                    : null,
                  contentSource && values.createLead
                    ? fetchLeadCreation({
                        ...values.createLead,
                        Lead_Source: cleanseZohoData(contentSource),
                      })
                    : null,
                ]),
              )
              .then(envelopes => {
                actions.setSubmitting(false);
                if (envelopes.every(isEnvelopeSuccess)) {
                  onSuccess(originalValues, actions);
                  actions.resetForm();
                } else if (envelopes.some(isEnvelopeSuccess)) {
                  captureEnvelopesErrors(envelopes);
                  onPartialSuccess(
                    originalValues,
                    actions,
                    envelopes.map(envelope => envelope?.response),
                  );
                } else {
                  captureEnvelopesErrors(envelopes);
                  onError(
                    // $FlowFixMe --> Reason: we know that all responses have failed
                    envelopes.find(envelope => !isEnvelopeSuccess(envelope))
                      ?.response,
                    originalValues,
                    actions,
                  );
                }
              })
              .catch((error: Error) => {
                actions.setSubmitting(false);
                onReject(error, originalValues, actions);
              });
          },
          [
            onSubmit,
            onSubmitMapValues,
            onSuccess,
            onError,
            onReject,
            listKey,
            contentSource,
            GCLID,
          ],
        )}
        validate={validate}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, handleReset, handleChange }) => (
          <form
            {...props}
            onReset={handleReset}
            onSubmit={handleSubmit}
            method="post"
          >
            <Field type="hidden" onChange={handleChange} />
            {!!GCLID && (
              <>
                <Field type="hidden" id="zc_gad" name="zc_gad" value={GCLID} />
                <Field type="hidden" id="GCLID" name="GCLID" value={GCLID} />
              </>
            )}
            {children}
          </form>
        )}
      </Formik>
      {/* The `script` tag is needed to grab the GCLID and send it back to Zoho */}
      <script
        type="text/javascript"
        src="https://crm.zoho.com/crm/javascript/zcga.js"
      />
    </>
  );
};

Form.defaultProps = {
  onReset: undefined,
  onSubmit: undefined,
  onSuccess: undefined,
  onPartialSuccess: undefined,
  onError: undefined,
  onReject: undefined,
  validate: undefined,
  validationSchema: undefined,
  validateOnChange: undefined,
  validateOnBlur: undefined,
  isInitialValid: undefined,
  enableReinitialize: undefined,
  initialValues: undefined,
  children: undefined,
  listKey: undefined,
};

export default Form;

function isEnvelopeSuccess(envelope) {
  return !envelope || envelope.response.ok;
}
function captureEnvelopesErrors(envelopes) {
  // TODO: capture error to keep track of issues. Use Sentry?
  console.warn('Some requests have failed:', envelopes);
}
